import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Action.css";
import * as moduleApi from "../rest/moduleApi";
import { apiCall } from "../rest/restApi";
import { toaster } from "../rest/apiUtils";
import swal from "sweetalert";
import LoadingOverlay from "react-loading-overlay";
import CircularProgress from "@mui/material/CircularProgress";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.number()
    .required("Age is required")
    .positive("Age must be positive")
    .integer("Age must be an integer"),
  // profilepic: Yup.mixed().required("Profile pic is required")
});

const Signup = ({ updateAuthentication, mobile }) => {
  // const [imagePreview, setImagePreview] = useState(null);
  const [isOtpVerification, setIsOtpVerification] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");
  const [userId, setUserId] = useState(0);

  // const handleImageChange = (event, setFieldValue) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setImagePreview(reader.result);
  //       setFieldValue("profilepic", reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleSubmit = (values) => {
    console.log(values);
    setIsLoading(true);
    moduleApi.postData(apiCall.signup, values).then((response) => {
      console.log(response);
      if (response?.status === 500) {
        setIsLoading(false);
        return swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (
        response?.message === "OTP sent to entered phone number, please verify"
      ) {
        setMobileNo(values?.mobileNumber);
        setUserId(response?.data);
        setIsOtpVerification(true);
        setIsLoading(false);
        return toaster("success", "OTP Sent Successfully");
      }
      // else if(response?.message === "User not exist navigate to signup"){
      //   updateSignUp();
      // }
    });
  };

  const getOtpVerification = () => {
    // let formData = new FormData();
    // formData.append("phoneNumber", mobileNo);
    // formData.append("otp", '0000');
    // console.log(formData);
    // const missed = inputs
    //   // eslint-disable-next-line
    //   .map((item, i) => {
    //     if (item === "") return i;
    //   })
    //   // .filter((item) => item || item === 0);
    // console.log("missed ", missed);
    // setMissing(missed);
    // if (missed.length) {
    //   return;
    // }
    // for (const value of inputs) {
    //   if (value === "") {
    //     return toaster("error", "Please Enter Otp");
    //   }
    // }
    setIsLoading(true);
    var payload = {
      phoneNumber: mobileNo,
      otp: enteredOtp,
      id: userId,
      // otp: inputs.join(""),
    };
    console.log(payload);
    moduleApi.postData(apiCall.verifySignupOtp, payload).then((response) => {
      if (response?.status === 500) {
        setIsLoading(false);
        return swal(response?.message, {
          icon: "error",
          dangerMode: true,
        });
      } else if (response?.data) {
        updateAuthentication();
        localStorage.setItem(
          "userLoginDetails",
          JSON.stringify(response?.data)
        );
        setIsLoading(false);
        return swal("User Registered and Logged in Successfully!", {
          icon: "success",
        });
      }
      // else if()
      // if(response?.message==="OTP sent to entered phone number, please verify"){
      //   setIsOtpVerification(true)
      //   return toaster('success', "OTP Sent Successfully")
      // }
    });
  };

  return (
    <LoadingOverlay
      active={isLoading}
      text={
        <p
          style={{
            color: "black",
          }}
        >
          <div>
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "25px",
                backgroundColor: "#FFFF",
                color: "black",
                borderColor: "white",
                fontWeight: "bold",
                fontSize: "16px",
              }}
              variant="primary"
              disabled
            >
              <CircularProgress />
              <span style={{textTransform:'math-auto'}}>&nbsp;&nbsp;Loading Please Wait...</span>
            </Button>
          </div>
        </p>
      }
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(192,192,192,0.4)",
        }),
        spinner: (base) => ({
          ...base,
          width: "80px",
          "& svg circle": {
            stroke: "rgb(136, 108, 192)",
          },
        }),
      }}
    >
      <div className="form-container">
        {!isOtpVerification && (
          <Formik
            initialValues={{
              name: "",
              mobileNumber: mobile ? mobile : "",
              email: "",
              gender: "",
              age: "",
              // profilepic: null
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              console.log(values);
              handleSubmit(values);
            }}
          >
            {({ setFieldValue, errors, touched }) => (
              <Form>
                {/* <div className="file-upload">
              <div className="image-preview-container">
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Profile Preview"
                    className="image-preview"
                  />
                ) : (
                  <div className="image-placeholder">No Image</div>
                )}
              </div>
              <input
                type="file"
                name="profilepic"
                id="profilepic"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(event) => handleImageChange(event, setFieldValue)}
              />
              <label htmlFor="profilepic">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  
                >
                  Upload Image
                </Button>
              </label>
              <FormHelperText style={{ color: "red" }}>
                <ErrorMessage name="profilepic" />
              </FormHelperText>
            </div> */}
                <Field
                  as={TextField}
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={<ErrorMessage name="name" />}
                  error={touched.name && Boolean(errors.name)}
                />
                <Field
                  as={TextField}
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={<ErrorMessage name="mobileNumber" />}
                  error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                />
                <Field
                  as={TextField}
                  name="email"
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={<ErrorMessage name="email" />}
                  error={touched.email && Boolean(errors.email)}
                />
                <FormControl
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={touched.gender && Boolean(errors.gender)}
                >
                  <InputLabel>Gender</InputLabel>
                  <Field as={Select} name="gender" label="Gender">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Field>
                  <FormHelperText>
                    <ErrorMessage name="gender" />
                  </FormHelperText>
                </FormControl>
                <Field
                  as={TextField}
                  name="age"
                  label="Age"
                  type="number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={
                    <ErrorMessage style={{ color: "red" }} name="age" />
                  }
                  error={touched.age && Boolean(errors.age)}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        )}
        {isOtpVerification && (
          <div
            className="App"
            style={{
              margin: "20px",
              padding: "15px",
              borderRadius: "12px",
              border: "1px solid #dae1e5",
            }}
          >
            <h2>OTP Verification</h2>
            <div style={{ marginTop: "10px" }}>
              <TextField
                label={`OTP`}
                type="number"
                value={enteredOtp}
                onChange={(e) => {
                  setEnteredOtp(e.target.value);
                }}
                placeholder="Enter Otp"
                // error={!!errors.childAges[index]}
                // helperText={errors.childAges[index]}
                margin="normal"
                fullWidth
              />
            </div>
            <Button
              style={{
                backgroundColor: "#1976d2",
                padding: "6px 16px",
                borderRadius: "4px",
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
                marginLeft: "10px",
                marginTop: "10px",
                color: "#fff",
              }}
              variant="contained"
              onClick={() => getOtpVerification()}
            >
              Submit
            </Button>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};

export default Signup;
