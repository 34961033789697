import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo2 from "../assets/logo2.png";
// import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LoginIcon from "@mui/icons-material/Login";
import CallIcon from "@mui/icons-material/Call";
import Signup from "./Signup";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Login from "./Login";
import ClearIcon from "@mui/icons-material/Clear";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import swal from "sweetalert";

const DrawerComp = (props) => {
  const { isAuthenticated, authenticate } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);

  const buttonStyle = {
    position: "relative",
    width: "40px",
    backgroundColor:'white',
    height: "40px", 
    color:"black",
    border: "none",
    borderRadius: "50%", // Makes it circular
    fontSize: "35px",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    marginRight:'20px',
    marginLeft:'10px',
    alignItems: "center",
    cursor: "pointer",
    outline: "none",
  };

  let navigate = useNavigate();

  const handleLoginModal = () => {
    setLoginModal(false);
    setSignupModal(false);
    authenticate(true);
  };

  const handleSignUp = () => {
    setLoginModal(false);
    setSignupModal(true);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
    
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "5px",
          }}
        >
          {/* <DirectionsBusFilledIcon sx={{ transform: "scale(2)" , marginRight:"10px"}} /> */}
          <img
            src={logo2}
            alt=""
            style={{
              height: "35px",
              width: "70px",
              margin: "15px",
              // marginBottom: "10px",
              borderRadius: "5px",
            }}
          />
          <span className="nav__logo2">Sri Vinayaka Travels</span>
          <button
      style={buttonStyle}
      onClick={()=>{
        setOpenDrawer(false)
      }}
      aria-label="Close"
    >
      &times;
    </button>
          {/* <span>Sri Vinayaka Travels</span> */}
        </div>
        <Divider />
        <List
          style={{
            width: "30vh",
            margin: "30px",
            marginTop: "10px",
            padding: "00px",
          }}
        >
          {isAuthenticated === false && (
            <ListItem
              disablePadding
              onClick={() => {
                setOpenDrawer(false)
                setLoginModal(true);
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary="Login" />
              </ListItemButton>
            </ListItem>
          )}
          {isAuthenticated === true && (
            <ListItem
              disablePadding
              onClick={() => {
                setOpenDrawer(false)
                navigate("details");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <HistoryIcon
                    style={{ marginRight: "5px", fontSize: "large" }}
                  />
                </ListItemIcon>
                <ListItemText primary="Your Trips" />
              </ListItemButton>
            </ListItem>
          )}
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CallIcon />
                </ListItemIcon>
                <ListItemText primary="9989023318" />
              </ListItemButton>
            </ListItem>
          {isAuthenticated === true && (
            <ListItem
              disablePadding
              onClick={() => {
                setOpenDrawer(false)
                swal({
                  title: "Log Out",
                  text: "Do you really want to Log Out?",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willDelete) => {
                  if (willDelete) {
                    localStorage.removeItem("userLoginDetails");
                    authenticate(false);
                    let path = window.location.pathname;
                    path = path.split("/");
                    if (path[path?.length - 1] === "details") {
                      navigate("tour-packages");
                    }
                    return swal("Logged Out Successfully!", {
                      icon: "success",
                    });
                  }
                });
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon
                    style={{ marginRight: "5px", fontSize: "large" }}
                  />{" "}
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
      <Modal className="fade" show={loginModal} centered>
        <Modal.Header>
          <Modal.Title>
            <h3>Login or Signup to Continue </h3>
          </Modal.Title>
          <ClearIcon
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => setLoginModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Login
            updateAuthentication={handleLoginModal}
            updateSignUp={handleSignUp}
          />
        </Modal.Body>
      </Modal>
      <Modal className="fade" show={signupModal} style={{ marginTop: "40px" }}>
        <Modal.Header>
          <Modal.Title>
            <h3>Register</h3>
          </Modal.Title>
          <ClearIcon
            style={{ marginRight: "5px", cursor: "pointer" }}
            onClick={() => setSignupModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowX: "auto" }}>
            <Signup updateAuthentication={handleLoginModal} />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DrawerComp;
